import 'slick-carousel';

$(document).ready(function() {
    $('.carousel-slider').slick({
        infinite: true,
        slidesToShow: 1,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 7000,
        prevArrow: $('.carousel-control-prev'),
        nextArrow: $('.carousel-control-next'),
    });
});