jQuery(function(){
    var Cookies = new (function(){
        var map = {};
        
        this.isset = function (key) { return typeof map[key] !== 'undefined'; };
        this.get = function (key) { return this.isset(key) ? map[key] : null; };
        this.set = function (key, val, expires) {
            map[key] = val;
            if(typeof expires === 'undefined'){expires = '';}
            else { expires = ';expires='+expires.toGMTString(); }
            document.cookie = key + "=" + val + expires;
        };
        this.unset = function(key) {
            if (this.isset(key)) {
                map[key] = undefined;
                document.cookie = key + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        };
        this.isenabled = function(){
            var key;
            do { key = 'cookietest'+Math.random(); } while (this.isset(key));
            this.set(key, key);
            if(document.cookie.indexOf(key+'='+key) === -1) { return false; }
            this.unset(key);
            return true;
        };
        
        var load = function(){
            document.cookie.split(';').forEach(function(str){
                var arr = str.trim().split('=');
                map[arr[0]] = arr[1];
            });
        };
        load();
    });
    
    var LocalStore = new (function(){
        this.isset = function (key) { return localStorage.getItem(key) !== null; };
        this.get = function (key) {
            var val = localStorage.getItem(key);
            var expires = localStorage.getItem(key + '-expires');
            if (val !== null && expires !== null && expires < new Date().getTime()) {
                this.unset(key);
                this.unset(key + '-expires');
                return null;
            }
            return val;
        };
        this.set = function (key, val, expires) {
            localStorage.setItem(key, val);
            if(typeof expires !== 'undefined'){ localStorage.setItem(key + '-expires', expires.getTime()); }
        };
        this.unset = function (key) { localStorage.removeItem(key); }
        this.isenabled = function(){
            if (typeof(Storage) === "undefined") { return false; }
            try {
                this.get('localstoragetest' + Math.random());
                return true;
            } catch (e) {
                return false;
            }
        };
    });
    
    // continue only if localStorage or Cookies is enabled
    var Store = LocalStore;
    if(! Store.isenabled()) {
        Store = Cookies;
        if(! Store.isenabled()) { return; }
    }
	
    // show cookie info
    var closed = Number(Store.get('cookieinfo-bbc')); // current status
    if (! closed) {
        var div = jQuery('#cookieinfo');
        div.show();
        div.find('.cookie_close').click(function(){
           div.hide();
           var expires = new Date(new Date().getTime() + 30*24*60*60*1000); // ~1 month
           Store.set('cookieinfo-bbc', 1, expires); // save
        });
    }
    
});