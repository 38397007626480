jQuery(function(){
    $(document).ready(function() {
        if (history.length == 0) {
            $('#zurueck').css('display','none');
        }
    });
    $('#zurueck').click(function goBack() {
        var prev = window.location.href;
        history.back();
        setTimeout(function(){
            if (window.location.href === prev) {
                window.location.href = 'https://www.bookedbyclick.de/'
            }
        }, 500);
    })
});